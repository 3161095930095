.breadcrumbs {
    list-style: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    $subColor: rgba($color: #fff, $alpha: 0.7);
    $mainColor: #3cef3c;
    $fontColor: white;
    padding: 0px;
    display: flex;
    align-items: baseline;
    margin: 0px;

    li {
        display: inline-block;
        position: relative;
        margin-top: -8px;
        color: $subColor;
    }

    li::before {
        content: '/';
        padding: 0 8px;
        font-weight: 700;
        color: $subColor;
        display: inline-block;
        margin-top: 8px;
    }

    li:hover::before {
        color: $subColor
    }

    li:not(:last-child):hover {
        color: $mainColor
    }

    li:last-child {
        color: $fontColor
    }

}
.homeIcon {
    cursor: pointer;
}