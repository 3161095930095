*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.right-0 {
  right: 0px;
}
.top-0 {
  top: 0px;
}
.top-1\/4 {
  top: 25%;
}
.z-\[9999\] {
  z-index: 9999;
}
.col-span-12 {
  grid-column: span 12 / span 12;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-6 {
  grid-column: span 6 / span 6;
}
.col-start-1 {
  grid-column-start: 1;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mt-4 {
  margin-top: 1rem;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.aspect-auto {
  aspect-ratio: auto;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
.h-32 {
  height: 8rem;
}
.h-64 {
  height: 16rem;
}
.h-\[156px\] {
  height: 156px;
}
.h-\[2px\] {
  height: 2px;
}
.h-\[350px\] {
  height: 350px;
}
.h-\[380px\] {
  height: 380px;
}
.h-\[600px\] {
  height: 600px;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.min-h-screen {
  min-height: 100vh;
}
.w-24 {
  width: 6rem;
}
.w-3\/5 {
  width: 60%;
}
.w-32 {
  width: 8rem;
}
.w-64 {
  width: 16rem;
}
.w-\[1280px\] {
  width: 1280px;
}
.w-\[156px\] {
  width: 156px;
}
.w-\[20\%\] {
  width: 20%;
}
.w-\[70vw\] {
  width: 70vw;
}
.w-\[800px\] {
  width: 800px;
}
.w-\[80vw\] {
  width: 80vw;
}
.w-\[960px\] {
  width: 960px;
}
.w-full {
  width: 100%;
}
.w-screen {
  width: 100vw;
}
.flex-1 {
  flex: 1 1 0%;
}
@keyframes pulse {
  50% {
    opacity: .5;
  }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.cursor-pointer {
  cursor: pointer;
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-stretch {
  align-items: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-16 {
  gap: 4rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-8 {
  gap: 2rem;
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.break-all {
  word-break: break-all;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.border-b {
  border-bottom-width: 1px;
}
.border-none {
  border-style: none;
}
.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}
.bg-\[\#45ffb1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(69 255 177 / var(--tw-bg-opacity));
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.bg-paper {
  --tw-bg-opacity: 1;
  background-color: rgb(32 41 64 / var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}
.object-fill {
  -o-object-fit: fill;
     object-fit: fill;
}
.p-2 {
  padding: 0.5rem;
}
.p-4 {
  padding: 1rem;
}
.p-8 {
  padding: 2rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pr-4 {
  padding-right: 1rem;
}
.text-left {
  text-align: left;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-transparent {
  color: transparent;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


#mui-rte-editor-container{
  border:  1px solid white;
}

.ql-toolbar.ql-snow {
  background-color: white;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 700;
}

@media (min-width: 640px) {
  .sm\:rounded-lg {
    border-radius: 0.5rem;
  }
}
.MyBreadcrumbs_breadcrumbs__gFLqm{list-style:none;font-family:Roboto,Helvetica,Arial,sans-serif;font-weight:400;font-size:1.25rem;line-height:1.5;letter-spacing:.00938em;padding:0px;display:flex;align-items:baseline;margin:0px}.MyBreadcrumbs_breadcrumbs__gFLqm li{display:inline-block;position:relative;margin-top:-8px;color:rgba(255,255,255,.7)}.MyBreadcrumbs_breadcrumbs__gFLqm li::before{content:"/";padding:0 8px;font-weight:700;color:rgba(255,255,255,.7);display:inline-block;margin-top:8px}.MyBreadcrumbs_breadcrumbs__gFLqm li:hover::before{color:rgba(255,255,255,.7)}.MyBreadcrumbs_breadcrumbs__gFLqm li:not(:last-child):hover{color:#3cef3c}.MyBreadcrumbs_breadcrumbs__gFLqm li:last-child{color:#fff}.MyBreadcrumbs_homeIcon__bUymc{cursor:pointer}
.MyMessage_main__owtTF{font-size:16px;white-space:pre-wrap}
.MyConfirm_container__PbnIo{min-height:150px;min-width:300px;background:#000;border:1px solid rgba(255,255,255,.2);box-sizing:border-box;border-radius:20px;-webkit-backdrop-filter:blur(60px);backdrop-filter:blur(60px);display:flex;flex-direction:column}.MyConfirm_container__PbnIo .MyConfirm_title__ZMrHs{font-family:"Poppins";font-style:normal;font-weight:600;font-size:24px;line-height:36px;height:72px;color:#fff;width:100%;display:flex;align-items:center;border-bottom:1px solid rgba(255,255,255,.1);padding-left:24px;padding-right:27.45px;justify-content:space-between}.MyConfirm_container__PbnIo .MyConfirm_title__ZMrHs .MyConfirm_close__dVAUA{height:10.5px;width:10.5px;position:relative;cursor:pointer;display:flex;align-items:center;justify-content:center}.MyConfirm_container__PbnIo .MyConfirm_main__Y1Lxy{margin:20px;color:#fff;white-space:pre-wrap;flex:1;display:flex;align-items:center;justify-content:center;text-align:center;border-radius:12px}.MyConfirm_container__PbnIo .MyConfirm_footer__T3l5I{height:40px;-moz-column-gap:5px;column-gap:5px;display:flex;align-items:center;justify-content:flex-end;margin-right:20px;margin-bottom:20px}.MyConfirm_dialogContent__Ox8Ih{background:#000}.MyConfirm_dialogAction__oWY63{background:#000}
