.container {
    min-height: 150px;
    min-width: 300px;
    background: black;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 20px;
    backdrop-filter: blur(60px);
    display: flex;
    flex-direction: column;
    

    .title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        height: 72px;
        display: flex;
        color: white;
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: 0.1);
        padding-left: 24px;
        padding-right: 27.45px;
        justify-content: space-between;
        .close {
            height: 10.5px;
            width: 10.5px;
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .main {
        // margin: 42px 39px;
        // background: #2A2C2F;
        margin: 20px;
        flex: 1;
        color: white;
        white-space: pre-wrap;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 12px;
    }

    .footer {
        display: flex;
        height: 40px;
        column-gap: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}

.dialogContent {
    background: black;
}

.dialogAction {
    background: black;
}